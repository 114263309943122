import React from 'react';

import { Route } from 'react-router-dom';

import Error401 from '@src/components/error/Error401';
import { ImportAdmins, ImportHomeOfficeEmployees } from '@src/components/users';
import { SessionsSettingEdit } from '@src/components/sessions_setting';

export default [
    <Route
        key="401"
        exact
        path="/unauthorized"
        component={Error401}
    />,
    <Route
        key="import_admins"
        exact
        path="/admins/import"
        component={ImportAdmins}
    />,
    <Route
        key="import_employees"
        exact
        path="/employees/import"
        component={ImportHomeOfficeEmployees}
    />,
    <Route
        key="sessions_setting"
        exact
        path="/session_timeouts"
        component={SessionsSettingEdit}
    />,
];
